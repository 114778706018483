/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const PostList = ({ posts }) => {
  return (
    <ul sx={styles.postList}>
      {posts.map((post) => {
        const { frontmatter, fields, excerpt, timeToRead } = post;
        const { title, date, description } = frontmatter;
        const { slug } = fields;

        return (
          <li key={slug} sx={styles.postListItem}>
            <GatsbyImage
              image={frontmatter.coverImage.childImageSharp.gatsbyImageData}
              alt=""
              imgClassName="img"
            />
            <div sx={styles.content}>
              <h2 sx={styles.title}>
                <Link to={slug}>{title}</Link>
              </h2>

              <div
                sx={styles.excerpt}
                dangerouslySetInnerHTML={{
                  __html: description || excerpt,
                }}
              />
              <div sx={styles.metaInfo}>
                <span>{date}</span>
                <span>{timeToRead} mins read</span>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default PostList;

const styles = {
  postList: {
    display: 'grid',
    gridGap: 4,
    gridTemplateColumns: 'repeat(auto-fit,minmax(35ch,1fr))',
    justifyItems: 'center',
  },

  postListItem: {
    position: 'relative',
    maxWidth: '28rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // boxShadow:
    //   '0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    borderRadius: '8px',

    '.img': {
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
  },

  content: {
    mt: 'auto',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid hsla(0, 0%, 0%, 0.1)',
  },

  title: {
    fontSize: [3, 4],
    fontWeight: 600,
    mb: 4,

    a: {
      color: 'purplegray',
    },

    'a::after': {
      position: 'absolute',
      content: '" "',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },

  excerpt: {
    mt: 'auto',
    color: 'rgba(107, 114, 128, 1)',
  },

  metaInfo: {
    fontSize: '0.75rem',
    mt: 4,
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(107, 114, 128, 1)',
  },
};
