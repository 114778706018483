/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import PostList from '../components/post-list';
import { graphql } from 'gatsby';

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout title="Blog">
      <div sx={styles.container}>
        <h1 sx={styles.heading}>From The Blog</h1>
        <PostList posts={posts} />
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          coverImage {
            childImageSharp {
              gatsbyImageData(height: 250)
            }
          }
        }
      }
    }
  }
`;
const styles = {
  container: { variant: 'layout.container', py: 6 },

  heading: {
    textAlign: 'center',
    pb: 4,
    color: 'purplegray',
  },
};
